import React from "react"
import { Box, Flex } from "rebass"

export const Background = ({ backgroundColor, textColor, ...props }) => (
  <Flex
    sx={{
      width: "100vw",
      paddingY: 5,
      paddingX: 3,
      justifyContent: "center",
      flexDirection: "row",
      alignItems: "center",
      backgroundColor: backgroundColor,
      color: textColor,
    }}
    {...props}
  />
)
export const Wrapper = ({
  backgroundColor,
  leftToRight,
  textColor,
  ...props
}) => (
    <Flex
      sx={{
        maxWidth: 1200,
        width: "100%",
        justifyContent: "center",
        flexWrap: "wrap",
        flexDirection: leftToRight ? "row" : "row-reverse",
        alignItems: "center",

        "& > *:not(:last-child)": {
          mr: leftToRight ? [0, 0, 5] : 0,
          ml: leftToRight ? 0 : [0, 0, 5],
        },

        ".youtube-player": {
          width: ["100%", 400, 450],
        },
      }}
      {...props}
    />
  )
export const ImageWrapper = props => (
  <Box
    sx={{
      width: ["100%", "50%", "45%"],
    }}
    {...props}
  />
)
export const TextWrapper = props => (
  <Box
    sx={{
      fontFamily: "body",
      width: ["100%", "100%", "45%"],
      h2: {
        fontWeight: "bold",
        fontSize: "4",
        lineHeight: 1.5,
        my: 3,
      },
      h3: {
        fontWeight: "bold",
        fontSize: 3,
        lineHeight: 1.5,
        my: 3,
      },
      a: {
        color: "inherit",
      },
      p: {
        fontSize: "2",
        mb: 3,
      },
    }}
    {...props}
  />
)
