import Image from "gatsby-image"
import React from "react"
import YoutubePlayer from "@components/YoutubePlayer"
import * as Styles from "./SectionSlide.styles"

function SectionSlide({
  backgroundColor,
  textColor,
  image,
  html,
  leftToRight,
  youtubeVideoId,
  altTextImage,
  videoStart = 0,
}) {
  return (
    <Styles.Background backgroundColor={backgroundColor} textColor={textColor}>
      <Styles.Wrapper leftToRight={leftToRight}>
        {!youtubeVideoId && image && (
          <Styles.ImageWrapper>
            <Image
              loading="eager"
              alt={altTextImage}
              fluid={image.childImageSharp.fluid}
            />
          </Styles.ImageWrapper>
        )}
        {youtubeVideoId && (
          <YoutubePlayer
            className="youtube-player"
            videoId={youtubeVideoId}
            opts={{
              playerVars: {
                start: videoStart,
              },
            }}
          />
        )}

        <Styles.TextWrapper dangerouslySetInnerHTML={{ __html: html }} />
      </Styles.Wrapper>
    </Styles.Background>
  )
}

export default SectionSlide
