import React from "react"
import { Box, Flex, Text } from "rebass"
import BackgroundImage from "gatsby-background-image"

export const Wrapper = ({ backgroundImage, children, ...props }) => (
  <BackgroundImage
    Tag={`section`}
    id={`test`}
    sx={{
      width: "100%",
      height: "100%",
    }}
    fluid={backgroundImage.childImageSharp?.fluid}
  >
    <Flex
      sx={{
        paddingY: 4,
        paddingX: 4,
        textAlign: "center",
        width: "100vw",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
      }}
      {...props}
    >
      {children}
    </Flex>
  </BackgroundImage>
)
export const Title = props => (
  <Text
    as="h1"
    sx={{
      color: "#FFF",
      fontSize: 4,
      fontFamily: "heading",
      fontWeight: "bold",
      mb: 2,
    }}
    {...props}
  />
)

export const Subtitle = props => (
  <Text
    as="p"
    sx={{
      color: "#FFF",
      fontSize: 2,
      mb: 4,
    }}
    {...props}
  />
)

export const ImageWrapper = p => (
  <Box
    sx={{
      width: [300, 350, 400],
    }}
    {...p}
  />
)
