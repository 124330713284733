import Image from "gatsby-image"
import React from "react"
import * as Styles from "./MainSlide.styles"

function MainSlide({
  title,
  subtitle,
  backgroundImage,
  mainImage,
  altTextMainImage,
  altTextBackgroundImage,
}) {
  return (
    <Styles.Wrapper
      title={altTextBackgroundImage}
      backgroundImage={backgroundImage}
    >
      <Styles.Title>{title}</Styles.Title>
      <Styles.Subtitle>{subtitle}</Styles.Subtitle>
      <Styles.ImageWrapper>
        <Image
          alt={altTextMainImage}
          fluid={mainImage.childImageSharp?.fluid}
        />
      </Styles.ImageWrapper>
    </Styles.Wrapper>
  )
}

export default MainSlide
