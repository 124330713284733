import BaseLayout from "@components/BaseLayout"
import MainSlide from "@components/Case/MainSlide"
import SectionSlide from "@components/Case/SectionSlide"
import Seo from "@components/Seo"
import { graphql } from "gatsby"
import React from "react"

function Case({ data, pageContext }) {
  const {
    markdownRemark: {
      frontmatter: { languages },
      fields: { sectionsHTML },
    },
  } = data
  const {
    title,
    subtitle,
    keywords,
    backgroundImage,
    altTextBackgroundImage,
    mainImage,
    altTextMainImage,
    sections,
  } = languages.find(lang => lang.language === pageContext.language)
  return (
    <BaseLayout>
      <Seo
        title={title}
        image={backgroundImage.publicURL}
        description={subtitle}
        keywords={keywords}
      />
      <MainSlide
        backgroundImage={backgroundImage}
        subtitle={subtitle}
        altTextBackgroundImage={altTextBackgroundImage}
        title={title}
        mainImage={mainImage}
        altTextMainImage={altTextMainImage}
      />
      {sections.map((section, index) => (
        <SectionSlide
          key={index}
          {...section}
          leftToRight={index % 2 === 0}
          html={sectionsHTML[index]}
        />
      ))}
    </BaseLayout>
  )
}

export const query = graphql`
  query CasePage($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      frontmatter {
        languages {
          language
          title
          subtitle
          backgroundImage {
            publicURL
            childImageSharp {
              fluid(quality: 90) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          altTextBackgroundImage
          keywords
          sections {
            backgroundColor
            textColor
            youtubeVideoId
            videoStart
            image {
              childImageSharp {
                fluid(maxHeight: 600) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            altTextImage
          }
          mainImage {
            childImageSharp {
              fluid(maxHeight: 600) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          altTextMainImage
        }
      }
      fields {
        slug
        sectionsHTML
      }
    }
  }
`

export default Case
